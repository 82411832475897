
import { Vue, Component, Watch } from 'nuxt-property-decorator'
import { ITariffItem } from '@/types/tariff'
import TariffCalc from '@/components/Tariff/TariffCalc.vue'
import UITabsItem from '@/components/UI/UITabsItem.vue'
import UITabsRow from '@/components/UI/UITabsRow.vue'
import UIDialog from '@/components/UI/UIDialog.vue'

@Component({
  components: { UIDialog, UITabsRow, UITabsItem, TariffCalc }
})
export default class PopupTariffUpgrade extends Vue {
  loading = false
  infoPromiseController: any = null

  tariffItems: ITariffItem[] | null = null
  selectedTariff: ITariffItem | null = null

  get upgradeOptions () {
    return [
      { label: 'Распределить по остатку', id: 1 },
      {
        label: this.selectedIsUpgrading
          ? 'Внести недостающие средства'
          : 'Вернуть переизбыток на баланс',
        id: 2
      }
    ]
  }

  selectedUpgradeOptionId = this.upgradeOptions[0].id

  partlyInfo = {
    days_count: 0,
    loading: false
  }

  /*
   * объект с информацией для улучшения или ухудшения тарифа
   * missing_amount - сколько не хватает денег для улучшения
   * refund_amount - сколько вернется денег за ухудшение тарифа
   */
  editInfo = {
    missing_amount: 0,
    refund_amount: 0,
    error: '',
    loading: false
  }

  async mounted () {
    this.loading = true
    try {
      const { data: tariffItems } = await this.$api.tariff.getTariffs()
      this.tariffItems = tariffItems

      this.selectedTariff = this.user.subscription_tariff.tariff || tariffItems[0]
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.loading = false
    }
  }

  @Watch('selectedTariff', { deep: true })
  async handleChangeTariff () {
    await this.getUpgradeInfo()
  }

  @Watch('selectedUpgradeOptionId')
  async handleChangeUpgradeOptionId () {
    await this.getUpgradeInfo()
  }

  get user () {
    return this.$store.getters['user/user']
  }

  get userTariff () {
    return this.user.subscription_tariff.tariff
  }

  get selectedTariffIsUserTariff () {
    return this.selectedTariff && this.selectedTariff.id === this.userTariff.id
  }

  get selectedIsUpgrading () {
    return this.selectedTariff && this.selectedTariff.max_cars_count > this.userTariff.max_cars_count
  }

  get isPartly () {
    return this.selectedUpgradeOptionId === 1
  }

  get enoughBalance () {
    if (this.isPartly) {
      return true
    }
    return this.user.balance >= this.editInfo.missing_amount
  }

  get btnDisabled () {
    return this.partlyInfo.loading || this.editInfo.loading
  }

  handleClose () {
    this.$emit('close')
  }

  topUpBalance () {
    this.$emit('balance', this.editInfo.missing_amount)
  }

  async getUpgradeInfo () {
    if (!this.selectedTariff) {
      return
    }
    if (this.userTariff.id === this.selectedTariff) {
      return
    }

    try {
      const formData = {
        months_count: 1,
        cars_count: this.selectedTariff.max_cars_count,
        partly: this.isPartly
      }

      if (this.infoPromiseController) {
        this.infoPromiseController.cancel()
      }
      this.infoPromiseController = this.$axios.CancelToken.source()
      this.editInfo.error = ''

      if (this.isPartly) {
        this.partlyInfo.loading = true
        const { days_count } = await this.$api.tariff.editTariffPartlyInfo(
          formData,
          {
            cancelToken: this.infoPromiseController.token
          }
        )
        this.partlyInfo.days_count = days_count
      } else {
        this.editInfo.loading = true
        const { missing_amount, refund_amount } = await this.$api.tariff.editTariffInfo(
          formData,
          {
            cancelToken: this.infoPromiseController.token
          }
        )
        this.editInfo.missing_amount = missing_amount
        this.editInfo.refund_amount = refund_amount
      }
      this.editInfo.loading = false
      this.partlyInfo.loading = false
    } catch (err) {
      if (this.$axios.isCancel(err)) {
        return
      }
      if (err.response) {
        this.editInfo.error = err.response.data.message || 'Произошла ошибка'
      }
      this.$sentry.captureException(err)
    }
  }

  async activateSubscription () {
    this.loading = true
    try {
      await this.$api.tariff.activate({
        cars_count: this.selectedTariff?.max_cars_count!,
        partly: this.isPartly
      })
      await this.$store.dispatch('user/fetchUser')
      this.handleClose()
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.loading = false
    }
  }
}
