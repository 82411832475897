type FileExtensionType = 'pdf' | 'xlsx'

export const downloadBlobFile = (blob: Blob, filename: string, fileExt: FileExtensionType) => {
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${filename}.${fileExt}`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
