import { Context } from '@nuxt/types'
import { AxiosError, AxiosRequestConfig } from 'axios'
import qs from 'qs'

export default function ({
  $axios,
  redirect,
  store
}: Context) {
  $axios.onError((error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      store.commit('auth/setAuthToken', null)
      redirect('/login')
    }
  })

  $axios.onRequest((config: AxiosRequestConfig) => {
    if (config.requireToken === undefined) {
      config.requireToken = true
    }
    if (!config.requireToken) {
      return
    }

    if (store.getters['auth/isAuthorized']) {
      $axios.setToken(store.getters['auth/token'], 'Bearer')
    }
    config.paramsSerializer = params => qs.stringify(params)
  })
}
