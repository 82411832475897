
import { Vue, Component } from 'nuxt-property-decorator'
import { SystemNotification } from '@/types/system-notification'
import UIAlert from '@/components/UI/UIAlert.vue'

const INTERVAL_TIME = 1000 * 60 * 30 // 30 минут

@Component({
  components: { UIAlert }
})
export default class SystemNotificationsProvider extends Vue {
  intervalId: null | ReturnType<typeof setInterval> = null
  notifications: SystemNotification[] = []

  async created () {
    await this.fetchNotifications()
    this.intervalId = setInterval(async () => {
      await this.fetchNotifications()
    }, INTERVAL_TIME)
  }

  beforeDestroy () {
    // @ts-ignore
    clearInterval(this.intervalId)
  }

  async fetchNotifications () {
    try {
      const { data: notifications } = await this.$api.systemNotifications.getNotifications()
      this.notifications = notifications
    } catch (err) {
      this.$sentry.captureException(err)
    }
  }

  async handleCloseAlert (id: number) {
    try {
      this.notifications = this.notifications.filter(item => item.id !== id)
      await this.$api.systemNotifications.hideNotification(id)
    } catch (err) {
      this.$sentry.captureException(err)
    }
  }
}
