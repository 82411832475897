import { Endpoints } from '~/connectors/endpoints'
export interface Token{
  id: number,
  created_at: string,
  token:string
}
const getToken = async () :Promise<undefined | Token> => {
  const { data } = await window.$nuxt.$axios.get(process.env.API_ENDPOINT + Endpoints.GET_API)
  return data.data[0]
}

export default getToken
