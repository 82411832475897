
import { Vue, Component } from 'nuxt-property-decorator'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { validMaskedPhone } from '~/utils/validations'

import UIDialog from '~/components/UI/UIDialog.vue'
import postPhoneCall from '@/connectors/user/post-callback,'

@Component({
  components: { UIDialog },
  directives: {
    mask
  },
  validations () {
    return {
      form: {
        fio: {
          required,
          maxLength: maxLength(50),
          minLength: minLength(2),
          isAlphabetic: value => /^[a-zA-Zа-яА-Я\s]*$/.test(value)
        },
        phone: {
          required,
          validMaskedPhone
        },
        comment: {
          maxLength: maxLength(500),
          isAlphabetic: value => /^[a-zA-Zа-яА-Я0-9.,!?;:'"\s]*$/.test(value)
        }
      }
    }
  }
})

export default class PopupCallback extends Vue {
  form: any = {
    phone: '',
    comment: '',
    fio: ''
  }

  loading: boolean = false

  submitted: boolean = false

  mounted () {
    const user = { ...this.$store.getters['user/user'] }
    this.form.fio = user.fio
    this.form.phone = user.phone
  }

  checkPhoneNumber () {
    // @ts-ignore
    this.$v.form.phone.$reset()
    if (this.form.phone[4] === '8') {
      this.form.phone = '+7 ('
    }
  }

  async submitForm () {
    this.$v.$touch()
    if (!this.$v.form.$invalid) {
      try {
        this.loading = true
        await postPhoneCall(this, this.form)
        this.submitted = true
      } catch (e: any) {
        if (e.response.status === 422) {
          this.$bvToast.toast('Произошла ошибка, попробуйте позже', {
            title: 'Ошибка!',
            solid: true,
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          })
        }
      } finally {
        this.loading = false
      }
    }
  }
}
