
import { Component, Vue } from 'nuxt-property-decorator'
import PopupCallback from './../../Popups/PopupCallback.vue'
import { menuItemAction } from '@/types/menuItem'
import UserMenuInfo from '@/components/User/UserMenu/UserMenuInfo.vue'
import UserMenuList from '@/components/User/UserMenu/UserMenuList.vue'
import PopupTariffBuy from '@/components/Popups/PopupTariffBuy.vue'
import PopupTariffUpgrade from '@/components/Popups/PopupTariffUpgrade.vue'
import PopupUserInfo from '@/components/Popups/PopupUserInfo.vue'
import PopupOrganizationInfo from '@/components/Popups/PopupOrganizationInfo.vue'
import PopupActsAndDocs from '@/components/Popups/PopupActsAndDocs.vue'
import PopupAutoInspections from '@/components/Popups/PopupAutoInspections.vue'
import PopupContract from '@/components/Popups/PopupContract.vue'
import PopupBalanceInvoice from '@/components/Popups/PopupBalanceInvoice.vue'
import PopupApiToken from '@/components/Popups/PopupApiToken.vue'

@Component({
  components: {
    PopupApiToken,
    PopupBalanceInvoice,
    PopupContract,
    PopupAutoInspections,
    PopupActsAndDocs,
    PopupOrganizationInfo,
    PopupUserInfo,
    UserMenuList,
    UserMenuInfo,
    PopupTariffUpgrade,
    PopupTariffBuy,
    PopupCallback
  },
  fetchOnServer: false
})
export default class UserMenu extends Vue {
  popup: boolean = false
  type: number = 0

  popupUserInfo = false
  popupOrgInfo = false
  popupTariffBuy = false
  popupTariffUpdate = false
  popupActsAndDocs = false
  popupAutoInspections = false
  popupContract = false
  popupBalance = false
  popupCallback: boolean = false
  popupToken: boolean = false
  fromBalance: boolean = false

  async fetch () {
    await this.$store.dispatch('user/fetchUser')
  }

  mounted () {
    if (window.location.hash === '#automatization') {
      this.popupAutoInspections = true
    }
  }

  handleMenuItem (command: menuItemAction) {
    if (command === '/login') {
      this.$store.commit('auth/setAuthToken', null)
      this.$store.commit('user/resetInfo')
    }
    if (command === 'user-info') {
      this.popupUserInfo = true
    }
    if (command === 'org-info') {
      this.popupOrgInfo = true
    }
    if (command === 'token') {
      this.popupToken = true
    }
    if (command === 'contract') {
      this.popupContract = true
    }
    if (command === 'payment') {
      this.showPaymentPopup()
    }
    if (command === 'subscription-buy') {
      this.popupTariffBuy = true
    }
    if (command === 'subscription-edit') {
      this.popupTariffUpdate = true
    }
    if (command === 'acts&docs') {
      this.popupActsAndDocs = true
    }
    if (command === 'auto_inspections') {
      this.popupAutoInspections = true
    }
    if (command.includes('/')) {
      this.$router.push(command)
    }

    // @ts-ignore - force close element popover component
    this.$refs.popover.handleBlur()
  }

  closePopupOrgInfo () {
    this.popupOrgInfo = false
    if (this.fromBalance && this.$store.getters['user/canDepositMoney']) {
      this.popupBalance = true
      this.fromBalance = false
    }
  }

  showPaymentPopup (amount?: number) {
    if (!this.$store.getters['user/canDepositMoney']) {
      this.popupOrgInfo = true
      this.fromBalance = true
      return
    }
    this.popupBalance = true
    this.$nextTick(() => {
      if (amount) {
        // @ts-ignore
        this.$refs.paymentPopup.amount = amount
      }
    })
  }
}
