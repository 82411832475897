
import { Prop, Component, Vue } from 'nuxt-property-decorator'

type Error = {
  statusCode: number
  message: string
}

type ErrorText = {
  title: string
  subtitle: string
}

type ErrorTexts = {
  [key: number]: ErrorText
  default: ErrorText
}

@Component
export default class ErrorPage extends Vue {
  @Prop({ required: true }) error: Error

  texts: ErrorTexts = {
    default: {
      title: 'Ошибка',
      subtitle: 'Что-то пошло не так, но мы попытаемся выяснить, в чем дело'
    }
  }

  get backToMainLink () {
    if (process.client) {
      return this.$store.getters['auth/isAuthorized'] ? '/' : '/login'
    }
    return '/'
  }

  get errorText (): ErrorText {
    const { statusCode } = this.error
    return this.texts[statusCode] || this.texts.default
  }
}
