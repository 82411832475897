
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ITariffItem, ITariffCoef } from '@/types/tariff'
import UIBadge from '~/components/UI/UIBadge.vue'
import UITabsRow from '~/components/UI/UITabsRow.vue'
import UITabsItem from '~/components/UI/UITabsItem.vue'

@Component({
  components: { UITabsItem, UITabsRow, UIBadge }
})
export default class TariffCalc extends Vue {
  @Prop() tariffItems: ITariffItem[]
  @Prop() cfItems: ITariffCoef[]
  @Prop() selectedTariff: ITariffItem
  @Prop() selectedMonthsPeriod: ITariffCoef
  @Prop() total: number

  get user () {
    return this.$store.getters['user/user']
  }

  get totalFor1Month () {
    if (!this.selectedTariff || !this.selectedMonthsPeriod) {
      return 0
    }
    return this.selectedTariff.price * this.selectedMonthsPeriod.months_count
  }

  cfToSaleAmount (n: number) {
    return n * 100 - 100
  }
}
