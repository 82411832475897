
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { IActCompletedWorkItem } from '~/types/actCompletedWorks'
import { downloadBlobFile } from '~/utils/downloadBlobFile'

@Component
export default class ActCompletedItem extends Vue {
  @Prop() act: IActCompletedWorkItem
  downloadingFile = false

  get fileName () {
    return `Акт выполненных работ за ${this.humanizeDate(this.act.start_of)} - ${this.humanizeDate(this.act.end_of)}`
  }

  humanizeDate (date: string) {
    return new Date(date).toLocaleDateString()
  }

  async downloadFile () {
    if (!this.act.link || this.downloadingFile) {
      return
    }
    try {
      this.downloadingFile = true
      const blobPdf = await this.$partnersApi.$get(this.act.link, { responseType: 'blob' })
      downloadBlobFile(blobPdf, this.fileName, 'pdf')
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.downloadingFile = false
    }
  }
}
