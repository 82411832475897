export default {
  namespaced: true,
  getters: {
    subscription (_state: any, _getters: any, _rootState: any, rootGetters: any) {
      return rootGetters['user/user']?.subscription_tariff
    },
    isTrial (_state: any, getters: any) {
      const subscription = getters.subscription
      return !!subscription && subscription.paid_amount === 0
    },
    isActive (_state: any, getters: any) {
      const subscription = getters.subscription
      return !!subscription && new Date() < new Date(subscription.end_of)
    },
    endsAt (_state: any, getters: any) {
      const subscription = getters.subscription
      return !!subscription && new Date(subscription.end_of).toLocaleDateString()
    }
  }
}
