import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { SystemNotification } from '@/types/system-notification'
import { fakePromise } from '@/utils/fakePromise'

export interface SystemNotificationsResources {
    getNotifications: () => Promise<{data: SystemNotification[]}>
    hideNotification: (id: number) => Promise<null>
}

const stub: SystemNotification[] = [{
  id: 1,
  type: 'warning',
  title: 'Неполадки в ГИС ГМП',
  description: 'Уважаемые пользователи! В данный момент наблюдаются неполадки в работе ГИС ГМП. В связи с этим при проведении проверок штрафов могут возникнуть ошибки. '
}, {
  id: 2,
  type: 'info',
  title: 'Warning',
  description: '<div>bla bla</div><strong>asdasd</strong>'
}]

export const systemNotifications = (axios: NuxtAxiosInstance): SystemNotificationsResources => ({
  getNotifications: () => axios.$get('/alerts'),
  hideNotification: (id: number) => axios.$post(`/alerts/${id}/hide`)
  // @ts-ignore
  // getNotifications: () => fakePromise({ data: stub }, 1000),
  // @ts-ignore
  // hideNotification: (_id: number) => fakePromise(null, 1000)
})
