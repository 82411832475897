
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { Invoice } from '@/types/invoice'
import { downloadBlobFile } from '@/utils/downloadBlobFile'

@Component({})
export default class InvoiceGenerationSuccess extends Vue {
  @Prop() invoice: Invoice
  downloadingFile = false

  async downloadBill () {
    if (!this.invoice.link || this.downloadingFile) {
      return
    }
    try {
      this.downloadingFile = true
      const blobPdf = await this.$partnersApi.$get(this.invoice.link, { responseType: 'blob' })
      downloadBlobFile(blobPdf, String(this.invoice.id), 'pdf')
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.downloadingFile = false
    }
  }
}
