
import { Vue, Component, Emit } from 'nuxt-property-decorator'
import { required } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { IUserLogin } from '~/types/user/login'
import { FIO, INN, KPP, validMaskedPhone } from '~/utils/validations'
import UIDialog from '~/components/UI/UIDialog.vue'
import UIAlert from '~/components/UI/UIAlert.vue'

@Component({
  components: { UIAlert, UIDialog },
  directives: {
    mask
  },
  validations () {
    return {
      form: {
        company_name: { required },
        ogrn: { required },
        inn: { required, INN },
        kpp: { KPP },
        address: { required },
        phone: { required, validMaskedPhone },
        gen_dir: { required, FIO },
        on_the_based: {
          required
        }
      }
    }
  }
})
export default class PopupOrganizationInfo extends Vue {
  @Emit()
  close () {

  }

  get user () {
    return this.$store.getters['user/user']
  }

  get showBalanceTip () {
    return !this.$store.getters['user/canDepositMoney']
  }

  form: Partial<IUserLogin> = {
    company_name: this.user.company_name || '',
    ogrn: this.user.ogrn || '',
    inn: this.user.inn || '',
    kpp: this.user.kpp || '',
    address: this.user.address || '',
    phone: this.user.phone || '',
    gen_dir: this.user.gen_dir || '',
    on_the_based: this.user.on_the_based || ''
  }

  formSubmitted = false

  async updateUser () {
    this.$v.$touch()
    if (this.$v.$invalid) {
      this.$bvToast.toast('Внимательно проверьте поля формы', {
        title: 'Внимание',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'warning'
      })

      return
    }
    try {
      this.formSubmitted = true
      const formData = { ...this.form }

      await this.$store.dispatch('user/updateUser', formData)

      this.$bvToast.toast('Данные организации успешно обновлены', {
        title: 'Успешное действие!',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      })
      setTimeout(() => {
        this.close
      }, 500);
    } catch (err) {
      this.$bvToast.toast('Не удалось обновить данные организации', {
        title: 'Ошибка',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      })
    } finally {
      this.formSubmitted = false
    }
  }
}
