
import { Vue, Component } from 'nuxt-property-decorator'
import { ITariffCoef, ITariffItem } from '@/types/tariff'
import TariffCalc from '@/components/Tariff/TariffCalc.vue'
import UIDialog from '~/components/UI/UIDialog.vue'

@Component({
  components: { UIDialog, TariffCalc }
})
export default class PopupTariffBuy extends Vue {
  loading = false

  tariffItems: ITariffItem[] | null = null
  cfItems: ITariffCoef[] | null = null
  selectedTariff: ITariffItem | null = null
  selectedMonthsPeriod: ITariffCoef | null = null

  async mounted () {
    this.loading = true
    try {
      const [
        { data: tariffItems },
        { data: cfItems }
      ] = await Promise.all([
        this.$api.tariff.getTariffs(),
        this.$api.tariff.getCoefficients()
      ])
      this.tariffItems = tariffItems
      this.cfItems = cfItems

      this.selectedMonthsPeriod = cfItems[0]
      const userSuggestTariff = tariffItems.find(t => t.max_cars_count >= this.user.cars)
      this.selectedTariff = userSuggestTariff || tariffItems[0]
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.loading = false
    }
  }

  get user () {
    return this.$store.getters['user/user']
  }

  get enoughBalance () {
    return this.user.balance >= this.total
  }

  get total () {
    if (!this.selectedTariff || !this.selectedMonthsPeriod) {
      return 0
    }
    return this.selectedMonthsPeriod.coefficient * this.selectedTariff.price * this.selectedMonthsPeriod.months_count
  }

  handleClose () {
    this.$emit('close')
  }

  topUpBalance () {
    const missingAmount = this.total - this.user.balance
    this.$emit('balance', missingAmount)
  }

  async activateSubscription () {
    this.loading = true
    try {
      const { months_count } = this.selectedMonthsPeriod!
      const cars_count = this.selectedTariff?.max_cars_count!
      await this.$api.tariff.activate({ months_count, cars_count })

      await this.$store.dispatch('user/fetchUser')
      this.handleClose()
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.loading = false
    }
  }
}
