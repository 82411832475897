import { helpers } from 'vuelidate/lib/validators'

export const allowedCharsEng = 'ABEKMHOPCTYXabekmhopctyx'
export const allowedCharsRu = 'АВЕКМНОРСТУХавекмнорстух'
export const allowedChars = allowedCharsRu + allowedCharsEng

export const hasRusWord = (val: string): boolean => /^[а-яёА-ЯË-]{3,}$/.test(val)
export const has2RusWords = (val: string): boolean => /^[а-яёА-ЯË-]+(?: [а-яёА-ЯË-]+)+$/.test(val)
export const has3RusWords = (val: string): boolean => /^[а-яёА-ЯË-]+(?: [а-яёА-ЯË-]+)+(?: [а-яёА-ЯË-]+)$/.test(val)

export const FIO = (val: string):boolean => !helpers.req(val) || /[а-яёА-ЯË-]{3,}(\s[а-яёА-ЯË-]{2,}){0,2}$/.test(val.trim())
export const INN = (val: string):boolean => !helpers.req(val) || /^(?:\d{10}|\d{12})$/.test(val)
export const KPP = (val: string):boolean => !helpers.req(val) || val.length === 9
export const validMaskedPhone = (val: string):boolean => !helpers.req(val) || val.length === 18
