export enum Endpoints {
  USER = '/user',
  USER_LOGIN = '/login',
  USER_FORGOT_PASSWORD = '/forgot-password',
  USER_RESET_PASSWORD = '/reset-password',
  USER_REGISTER = '/register',
  USER_SETTINGS = '/settings',

  POST_PAYMENT = '/payment/form',

  GET_ACCRUALS = '/accruals',
  GET_DISTINCT_RECORDS = '/distinct-records',
  DOWNLOAD_1C_REESTR = '/download-1c-reestr',
  ARCHIVE_ACCRUAL = '/accruals/archive-toggle/',

  // accounts
  GET_ACCOUNTS = '/accounts',
  CREATE_ACCOUNT = '/accounts',
  PUT_ACCOUNT = '/accounts/{{0}}',
  SHOW_ACCOUNT = '/accounts/{{0}}',
  DELETE_ACCOUNT = '/accounts/{{0}}',

  // api-token
  GET_API = '/tokens',
  CREATE_API = '/tokens/create',
  DELETE_API = '/tokens/delete/',
  GET_STAT = '/statistic',

  // requisites
  GET_REQUISITES = '/documents',
  SHOW_REQUISITES = '/documents/{{0}}',
  CREATE_REQUISITES = '/documents',
  PUT_REQUISITES = '/documents/{{0}}',

  // rent
  PARKS = '/autoparks',
  BODY_PARKS ='/autoparks/',

  GET_FULL_CHECK_BATCH = '/full-check/{{0}}',
  POST_DISPATCH_ALL = '/documents/dispatchAll',

  UPLOAD_REESTR = '/upload-reestr',

  GET_OFERTA = '/oferta',
  GET_TARIFS = '/payment/available',

  // dadata
  GET_SUGGESTONS_BANK = '/suggestions/bank?query={{0}}',
  GET_SUGGESTONS_COMPANY = '/suggestions/bank?query={{0}}'
}
