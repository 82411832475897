import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Invoice } from '@/types/invoice'

export interface InvoiceResources {
  createInvoice: (amount: number) => Promise<{ data: Invoice }>
  getInvoiceList: () => Promise<{ data: Invoice[] }>
  getInvoiceAsPdf: (id: number) => Promise<Blob>
}

export const invoices = (axios: NuxtAxiosInstance): InvoiceResources => ({
  createInvoice: (amount: number) => axios.$post<{ data: Invoice }>('/invoices', { amount }),
  getInvoiceList: () => axios.$get<{ data: Invoice[] }>('/invoices'),
  getInvoiceAsPdf: (id: number) => axios.$get<Blob>(`/invoices/${id}`, { responseType: 'blob' })
})
