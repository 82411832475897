// import { AUTH_TOKEN_KEY } from '@/store/auth'

export default {
  namespaced: true,
  actions: {
    nuxtClientInit () {
      // commit('auth/setAuthToken', localStorage.getItem(AUTH_TOKEN_KEY))
    }
  }
}
