
import { Vue, Component } from 'nuxt-property-decorator'
import UIDialog from '@/components/UI/UIDialog.vue'
import ContractOffer from '@/components/Contract/ContractOffer.vue'

@Component({
  components: { ContractOffer, UIDialog }
})
export default class PopupContract extends Vue {

}
