
import { Vue, Component, Emit } from 'nuxt-property-decorator'
import { email, required, sameAs } from 'vuelidate/lib/validators'
import UIDialog from '~/components/UI/UIDialog.vue'
import { IUserLogin } from '~/types/user/login'
import { FIO } from '~/utils/validations'

interface UserSettings {
  password?: string
  password_confirmation?: string
}

@Component({
  components: { UIDialog },
  validations () {
    return {
      form: {
        fio: { required, FIO },
        email: { required, email },
        password: {},
        password_confirmation: {
          sameAsPassword: sameAs('password')
        }
      }
    }
  }
})
export default class PopupUserInfo extends Vue {
  @Emit()
  close () {

  }

  get user () {
    return this.$store.getters['user/user']
  }

  form: Partial<IUserLogin> & UserSettings = {
    fio: this.user.fio || '',
    email: this.user.email || '',
    password: '',
    password_confirmation: ''
  }

  formSubmitted = false

  async updateUser () {
    this.$v.$touch()
    if (this.$v.$invalid) {
      this.$bvToast.toast('Внимательно проверьте поля формы', {
        title: 'Внимание',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'warning'
      })

      return
    }
    try {
      this.formSubmitted = true
      const formData = { ...this.form }

      if (!this.form.password) {
        delete formData.password
        delete formData.password_confirmation
      }
      await this.$store.dispatch('user/updateUser', formData)

      this.$bvToast.toast('Данные учетной записи успешно обновлены', {
        title: 'Успешное дейтсвие!',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      })
      // this.close()
    } catch (err) {
      this.$bvToast.toast('Не удалось обновить данные пользователя', {
        title: 'Ошибка',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      })
    } finally {
      this.formSubmitted = false
    }
  }
}
