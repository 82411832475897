
import { Vue, Component } from 'nuxt-property-decorator'
import AppHeaderNav from './AppHeaderNav.vue'
import UserMenu from '~/components/User/UserMenu/UserMenu.vue'

@Component({
  components: { UserMenu, AppHeaderNav }
})
export default class AppHeader extends Vue {

}
