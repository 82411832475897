<script>
export default {
  name: 'UIBadge'
}
</script>

<template>
  <div class="ui-badge">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.ui-badge {
  padding: 0 6px;
  border-radius: 24px;
  background: #F73F3F;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 2;
  letter-spacing: -0.4px;
}
</style>
