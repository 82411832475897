
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  components: {},
  fetchOnServer: false,
  computed: {
    finesCount () {
      return this.$store.getters['user/user'].accruals_count
    },
    tollRoadsCount () {
      return this.$store.getters['user/user'].acrruals_toll_roads
    }
  }
})
export default class AppHeaderNav extends Vue {
  mounted () {
  }
}
