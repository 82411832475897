
import { Vue, Component } from 'nuxt-property-decorator'
import getOferta from '@/connectors/user/get-oferta'

@Component({})
export default class ContractOffer extends Vue {
  loading = false
  oferta: string = ''

  async created () {
    try {
      this.loading = true
      this.oferta = await getOferta()
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.loading = false
    }
  }
}
