import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Endpoints } from './endpoints'

type RegistrationData = {
  company_name: string,
  phone: string,
  agreement: 1 | 0,
  email: string,
  password: string,
  password_confirmation: string
}

export interface AuthResources {
  register: (payload: RegistrationData) => Promise<{ token: string }>
  login: (email: string, password: string) => Promise<{ token: string }>
  forgotPassword: (email: string) => Promise<{ success: true }>
  resetPassword: (
    email: string,
    resetToken: string,
    password: string,
    passwordConfirmation: string
  ) => Promise<{ success: true }>
}

export const Auth = (axios: NuxtAxiosInstance): AuthResources => ({
  register: (formData) => {
    return axios.$post(Endpoints.USER_REGISTER, formData, { requireToken: false })
  },
  login (email: string, password: string) {
    return axios.$post(Endpoints.USER_LOGIN, { email, password }, { requireToken: false })
  },
  forgotPassword (email: string) {
    return axios.$post(Endpoints.USER_FORGOT_PASSWORD, { email }, { requireToken: false })
  },
  resetPassword (
    email: string,
    resetToken: string,
    password: string,
    passwordConfirmation: string
  ) {
    return axios.$post(Endpoints.USER_RESET_PASSWORD, {
      email,
      password,
      password_confirmation: passwordConfirmation,
      token: resetToken
    },
    {
      requireToken: false
    })
  }
})
