import { AxiosError, AxiosRequestConfig } from 'axios'
import qs from 'qs'
import { Inject } from '@nuxt/types/app'
import { Context } from '@nuxt/types'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Auth, AuthResources } from '@/plugins/api/auth'
import { Tariff, TariffResources } from '@/plugins/api/tariff'
import { ActCompletedWorks, ActCompletedWorksResources } from '~/plugins/api/actCompletedWorks'
import { invoices, InvoiceResources } from '@/plugins/api/invoice'
import { systemNotifications, SystemNotificationsResources } from '@/plugins/api/system-notifications'

export interface APIPlugin {
    auth: AuthResources
    tariff: TariffResources
    actCompletedWorks: ActCompletedWorksResources
    invoice: InvoiceResources
    systemNotifications: SystemNotificationsResources
}

declare module '@nuxt/types' {
    interface Context {
        $api: APIPlugin,
        $partnersApi: NuxtAxiosInstance
    }

    interface NuxtAppOptions {
        $api: APIPlugin,
        $partnersApi: NuxtAxiosInstance
    }
}
declare module 'vue/types/vue' {
    interface Vue {
        $api: APIPlugin,
        $partnersApi: NuxtAxiosInstance
    }
}

declare module 'vuex/types/index' {
    interface Store<S> {
        $api: APIPlugin,
        $partnersApi: NuxtAxiosInstance
    }
}

export default function ({
  store,
  redirect,
  $axios,
  $config
}: Context, inject: Inject) {
  const partnersApi = $axios.create({
    baseURL: $config.API_ENDPOINT || 'https://partners-api.simplepayments24.ru/api',
    withCredentials: true,
    paramsSerializer: params => qs.stringify(params)
  })

  const handle401Error = (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      store.commit('auth/setAuthToken', null)
      partnersApi.setToken(false)
      redirect('/login')
    }
  }

  const requestInterceptor = (config: AxiosRequestConfig) => {
    if (config.requireToken === undefined) {
      config.requireToken = true
    }
    if (!config.requireToken) {
      return
    }

    if (store.getters['auth/isAuthorized']) {
      const token = store.getters['auth/token']
      partnersApi.setToken(token, 'Bearer')
    }
  }

  partnersApi.onRequest(requestInterceptor)
  partnersApi.onError(handle401Error)

  const api: APIPlugin = {
    auth: Auth(partnersApi),
    tariff: Tariff(partnersApi),
    actCompletedWorks: ActCompletedWorks(partnersApi),
    invoice: invoices(partnersApi),
    systemNotifications: systemNotifications(partnersApi)
  }

  inject('partnersApi', partnersApi)
  inject('api', api)
}
