
import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'

@Component({})
export default class UIAlert extends Vue {
  @Prop({ default: 'primary' }) theme: 'primary' | 'danger' | 'success'
  @Prop({ default: false }) closable: boolean

  @Emit()
  close () {
  }
}
