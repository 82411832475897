
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  middleware: ({ redirect, store }) => {
    if (store.getters['auth/isAuthorized']) {
      redirect('/')
    }
  }
})
export default class extends Vue {
}
