
import { Vue, Component, Watch } from 'nuxt-property-decorator'
import { mask } from 'vue-the-mask'
import { email } from 'vuelidate/lib/validators'
import UIDialog from '@/components/UI/UIDialog.vue'
import putUserSettings from '@/connectors/user/put-settings'

@Component({
  components: { UIDialog },
  directives: {
    mask
  },
  validations () {
    return {
      form: {
        email_for_registry: {
          email
        }
      }
    }
  }
})
export default class PopupAutoInspections extends Vue {
  week = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс']

  loader = false
  form: any = {
    days: [],
    time_at: '',
    email_for_registry: ''
  }

  @Watch('$store.state.user.user', { immediate: true })
  getUser (user: any) {
    const settings = user.settings
    if (settings) {
      this.form.days = settings.days.map((i: number) => this.week[i - 1])
      this.form.time_at = settings.time_at.slice(0, 5)
      this.form.email_for_registry = settings.email_for_registry
    }
  }

  handleSubmit () {
    this.$v.$touch()

    if (!this.$v.form.$invalid) {
      this.putSettings()
    } else {
      this.$bvToast.toast('Не удалось обновить настройки автоматизации', {
        title: 'Ошибка',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      })
    }
  }

  async putSettings () {
    this.loader = true
    const dayIndex: number[] = []
    this.form.days.forEach((item: string) => {
      dayIndex.push(this.week.indexOf(item) + 1)
    })
    this.form.days = [...dayIndex]
    const response = await putUserSettings(this, this.form).then((res: any) => res.data)
    this.$bvToast.toast('Данные успешно сохранены', {
      title: 'Успешное дейтсвие!',
      solid: true,
      toaster: 'b-toaster-bottom-right',
      variant: 'success'
    })
    await this.$store.dispatch('user/setInfo', response)
    this.loader = false
  }
}
