export const AUTH_TOKEN_KEY = 'storedToken'

export default {
  namespaced: true,
  state: () => ({
    authToken: localStorage.getItem(AUTH_TOKEN_KEY) || null
  }),
  getters: {
    token (state: any) {
      return state.authToken
    },
    isAuthorized (state: any) {
      return !!state.authToken
    }
  },
  mutations: {
    setAuthToken (state: any, token: any) {
      if (token) {
        localStorage.setItem(AUTH_TOKEN_KEY, token)
      } else {
        localStorage.removeItem(AUTH_TOKEN_KEY)
      }
      state.authToken = token
    }
  }
}
