import { Context } from '@nuxt/types'

export default ({
  store,
  route,
  redirect
}: Context) => {
  /**
   * если пользователь приходит с токеном на урл /auth?token=, то перетираем существующий токен новым
   */
  if (route.query.token && route.path === '/auth') {
    const parseQuery = require('qs').parse(route.query)
    store.commit('auth/setAuthToken', parseQuery.token)

    /*
      если пользователь приходит с query параметром newUser, то редиректим его на реквизиты и выставляем токен для модалки - приветствия
      используется для регистрации из лендоса ПП
     */
    if (parseQuery.newUser) {
      localStorage.setItem('newUser', '1')
      redirect('/')
      return
    }

    redirect('/')
  }

  // Если не авторизован - редирект на логин
  if (!store.getters['auth/isAuthorized']) {
    return redirect('/login')
  }

  /*
   * meta - requiredUserAccess
   * Проверяем доступ пользователя к странице по настройкам пользователя
   */
  // @ts-ignore
  const menu = route?.meta[0]?.requiredUserAccess || null

  // Если страница не доступна - редирект на главную
  if (menu && !store.getters['user/isUserAllowedForRoute'](menu)) {
    return redirect('/')
  }
}
