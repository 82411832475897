import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { IActCompletedWorkItem } from '~/types/actCompletedWorks'

export interface ActCompletedWorksResources {
  getActs: () => Promise<{ data: IActCompletedWorkItem[] }>
  getActAsPdf: (id: number) => Promise<Blob>
}

export const ActCompletedWorks = (axios: NuxtAxiosInstance): ActCompletedWorksResources => ({
  getActs: () => axios.$get<{ data: IActCompletedWorkItem[] }>('/act_completed_works'),
  getActAsPdf: (id: number) => axios.$get<Blob>(`/act_completed_works/${id}`, { responseType: 'blob' })
})
