import { Endpoints } from '~/connectors/endpoints'

const createToken = (token: string | undefined) => {
  if (token !== undefined) {
    return window.$nuxt.$axios.delete(process.env.API_ENDPOINT + Endpoints.DELETE_API + token).then(() => {
      return window.$nuxt.$axios.post(process.env.API_ENDPOINT + Endpoints.CREATE_API)
        .then(({ data }: any) => data)
    })
  } else {
    return window.$nuxt.$axios.post(process.env.API_ENDPOINT + Endpoints.CREATE_API)
      .then(({ data }: any) => data)
  }
}

export default createToken
