import * as process from 'process'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

const YM_ID = '96881991'

export enum YMGoals {
    AUTOPAYMENT_CONNECT_CLICK = 'click-connect-auto-payment',
    AUTOPAYMENT_CONNECT_SUCCESS = 'success-connect-auto-payment'
}

declare global {
    interface Window {
        ym: any
    }
}

export interface YMPlugin {
    reachGoal(goal: YMGoals | string): void
}

declare module '@nuxt/types' {
    interface Context {
        $yandexMetrics: YMPlugin
    }

    interface NuxtAppOptions {
        $yandexMetrics: YMPlugin
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $yandexMetrics: YMPlugin
    }
}

const onLoad = (callback: { (): void; (): void }, delay = 1) => {
  // missed document the load event, run now
  if (document.readyState === 'complete') {
    setTimeout(() => callback(), delay)
  } else {
    window.addEventListener('load', function () {
      setTimeout(() => callback(), delay)
    })
  }
}

export default (_ctx: Context, inject: Inject) => {
  if (!YM_ID || _ctx.isDev) {
    return false
  }
  const callYM = (target: string, ...args: string[]) => {
    try {
      window.ym(YM_ID, target, ...args)
    } catch (err) {
      console.error(err)
    }
  }
  /**
     * Инициализация метрики
     */
  onLoad(() => {
    /* eslint-disable */
        (function (m, e, t, r, i, k, a) {
            // @ts-ignore
          m[i] = m[i] || function () {
                // @ts-ignore
              (m[i].a = m[i].a || []).push(arguments)
            }
            // @ts-ignore
          m[i].l = Number(new Date())
            // @ts-ignore
            k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
        })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym')
        /* eslint-enable */

    // @ts-ignore
    callYM('init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    })
  })

  /**
     * Метод отправки reachGoal в яндекс метрику
     * @param {string} goal - идентификатор цели
     */
  const reachGoal = (goal: YMGoals | string): void => {
    if (!goal) {
      console.error('[Analytics]: goal is required')
    }
    callYM('reachGoal', goal)
  }

  inject('yandexMetrics', {
    reachGoal
  })
}
