
import { Vue, Component } from 'nuxt-property-decorator'
import { IActCompletedWorkItem } from '~/types/actCompletedWorks'
import UIDialog from '~/components/UI/UIDialog.vue'
import ActCompletedItem from '~/components/Act/ActCompletedItem.vue'

@Component({
  components: { ActCompletedItem, UIDialog }
})
export default class PopupActsAndDocs extends Vue {
  loading = false
  acts: IActCompletedWorkItem[] = []

  async mounted () {
    try {
      this.loading = true
      const { data: acts } = await this.$api.actCompletedWorks.getActs()
      this.acts = acts
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.loading = false
    }
  }
}
