
import { Vue, Component } from 'nuxt-property-decorator'
import { maxLength, required } from 'vuelidate/lib/validators'
import { Invoice } from '@/types/invoice'
import UIDialog from '@/components/UI/UIDialog.vue'
import UIAlert from '@/components/UI/UIAlert.vue'
import InvoiceGenerationSuccess from '@/components/Invoice/InvoiceGenerationSuccess.vue'

@Component({
  components: { InvoiceGenerationSuccess, UIAlert, UIDialog },
  validations () {
    return {
      amount: {
        required,
        maxLength: maxLength(6)
      }
    }
  }
})
export default class PopupBalance extends Vue {
  amount = null
  loader = false
  invoice: null | Invoice = null
  invoiceGenerationSuccess = false

  mounted () {
    this.$v.$reset()
  }

  async getPaymentForm () {
    try {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }

      this.loader = true
      const { data: invoice } = await this.$api.invoice.createInvoice(Number(this.amount))
      this.invoice = invoice
      this.invoiceGenerationSuccess = true
    } catch (err) {
      this.$sentry.captureException(err)
    } finally {
      this.loader = false
    }
  }

  closeDialog () {
    this.$emit('close')
  }
}
