
import { Vue, Component, Emit } from 'nuxt-property-decorator'
import UserMenuItemInfo from '~/components/User/UserMenu/UserMenuItemInfo.vue'
import { menuItemAction } from '~/types/menuItem'

@Component({
  components: { UserMenuItemInfo }
})
export default class UserMenu extends Vue {
  @Emit('menu-click')
  handleMenuClick (_menuItem: menuItemAction) {
  }

  get user () {
    return this.$store.getters['user/user']
  }
}
