
import { Vue, Component, Ref } from 'nuxt-property-decorator'
import UIDialog from '@/components/UI/UIDialog.vue'
import getToken, { Token } from '@/connectors/apitoken/get-token'
import getStat from '@/connectors/apitoken/get-statistic'
import createToken from '@/connectors/apitoken/create-token'

interface Statistic {
  check_documents: number,
  fetching_gibdd_photos: number,
  fetching_madi_photos: number,
  payment_generation: number
}

@Component({
  components: { UIDialog }
})
export default class PopupApiToken extends Vue {
  @Ref() tokenInput: HTMLInputElement

  pending = false
  tokenData: Token | undefined = undefined
  token: string = ''
  statistic: Statistic | {} = {}
  openCreateToken: boolean = false

  async mounted () {
    this.pending = true
    const [stat, token] = await Promise.all([getStat(), getToken()])
    this.statistic = stat
    this.tokenData = token

    if (this.tokenData !== undefined) {
      this.token = this.tokenData.token
      this.pending = false
    } else {
      this.token = ''
      this.pending = false
    }
  }

  copyToken () {
    this.tokenInput.select()
    document.execCommand('copy')
  }

  async createToken () {
    this.pending = true
    this.openCreateToken = false
    if (this.tokenData) {
      await createToken(this.token.split('|')[0]).then((response) => {
        this.token = response.data.token
        this.pending = false
        this.$bvToast.toast('Токен успешно создан', {
          title: 'Успешное дейтсвие!',
          solid: true,
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        })
      })
    } else {
      await createToken(undefined).then((response) => {
        this.token = response.data.token
        this.pending = false
        this.$bvToast.toast('Токен успешно создан', {
          title: 'Успешное дейтсвие!',
          solid: true,
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        })
      })
    }
  }

  closeDialog () {
    this.$emit('close')
  }
}
