import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosRequestConfig } from 'axios'
import { ITariffActivatePayload, ITariffCoef, ITariffEditInfo, ITariffItem } from '~/types/tariff'

export interface TariffResources {
  getTariffs: () => Promise<{ data: ITariffItem[] }>
  getCoefficients: () => Promise<{ data: ITariffCoef[] }>
  activate: (payload: ITariffActivatePayload) => Promise<{ success: boolean }>
  editTariffInfo: (payload: ITariffActivatePayload, params?: AxiosRequestConfig) => Promise<ITariffEditInfo>
  editTariffPartlyInfo: (payload: ITariffActivatePayload, params?: AxiosRequestConfig) => Promise<{ days_count: number }>
}

export const Tariff = (axios: NuxtAxiosInstance): TariffResources => ({
  getTariffs: () => axios.$get<{ data: ITariffItem[] }>('/tariffs'),
  getCoefficients: () => axios.$get<{ data: ITariffCoef[] }>('/tariffs/coefficients'),
  activate: (payload: ITariffActivatePayload) => axios.$post<{ success: boolean }>('/tariffs/activate', payload),
  editTariffInfo: (payload: ITariffActivatePayload, params = {}) => {
    return axios.$post<ITariffEditInfo>('/tariffs/activate_info', payload, params)
  },
  editTariffPartlyInfo: (payload: ITariffActivatePayload, params = {}) => {
    return axios.$post<{ days_count: number }>('/tariffs/activate_partly_info', payload, params)
  }
})
