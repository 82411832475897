import VueLib from 'vue'
import * as Sentry from '@sentry/browser'

import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {"dsn":"https:\u002F\u002F87230219407f40de8e1b1259ed9549f9@sentry.xn--80ajbekothchmme5j.xn--p1ai\u002F19","environment":"production","normalizeDepth":7,"beforeSend":function(event) {
        if (event.level === 'info') {
          return null;
        }

        return event;
      },"beforeBreadcrumb":function(breadcrumb) {
        if (breadcrumb.category === 'xhr') {
          if (breadcrumb.data) {
            if (breadcrumb.data.url.match('mc.yandex.ru')) {
              return null;
            }
          }
        }
        return breadcrumb;
      }}
  config.integrations = [
    new Dedupe({}),
    new ExtraErrorData({}),
    new ReportingObserver({}),
    new RewriteFrames({}),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
