import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _38122b29 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _b153fe3c = () => interopDefault(import('../pages/checking_account/index.vue' /* webpackChunkName: "pages/checking_account/index" */))
const _4b954f32 = () => interopDefault(import('../pages/fines.vue' /* webpackChunkName: "pages/fines" */))
const _158b23fe = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _6e8f76bc = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _6c4ddcfa = () => interopDefault(import('../pages/pay-accrual.vue' /* webpackChunkName: "pages/pay-accrual" */))
const _107eac7f = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _7de72748 = () => interopDefault(import('../pages/payment-fail.vue' /* webpackChunkName: "pages/payment-fail" */))
const _b9421f84 = () => interopDefault(import('../pages/payment-fail-accrual.vue' /* webpackChunkName: "pages/payment-fail-accrual" */))
const _21af6db5 = () => interopDefault(import('../pages/payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _8c680e0c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _ec0d3be0 = () => interopDefault(import('../pages/rent.vue' /* webpackChunkName: "pages/rent" */))
const _1a14db18 = () => interopDefault(import('../pages/requisites/index.vue' /* webpackChunkName: "pages/requisites/index" */))
const _637141d8 = () => interopDefault(import('../pages/toll-roads.vue' /* webpackChunkName: "pages/toll-roads" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0a5fe629 = () => interopDefault(import('../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _38122b29,
    name: "account"
  }, {
    path: "/checking_account",
    component: _b153fe3c,
    name: "checking_account"
  }, {
    path: "/fines",
    component: _4b954f32,
    name: "fines"
  }, {
    path: "/forgot-password",
    component: _158b23fe,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _6e8f76bc,
    name: "login"
  }, {
    path: "/pay-accrual",
    component: _6c4ddcfa,
    name: "pay-accrual"
  }, {
    path: "/payment",
    component: _107eac7f,
    name: "payment"
  }, {
    path: "/payment-fail",
    component: _7de72748,
    name: "payment-fail"
  }, {
    path: "/payment-fail-accrual",
    component: _b9421f84,
    name: "payment-fail-accrual"
  }, {
    path: "/payment-success",
    component: _21af6db5,
    name: "payment-success"
  }, {
    path: "/register",
    component: _8c680e0c,
    name: "register"
  }, {
    path: "/rent",
    component: _ec0d3be0,
    name: "rent"
  }, {
    path: "/requisites",
    component: _1a14db18,
    name: "requisites"
  }, {
    path: "/toll-roads",
    component: _637141d8,
    name: "toll-roads"
  }, {
    path: "/",
    component: _01debcea,
    name: "index"
  }, {
    path: "/reset-password/:token?",
    component: _0a5fe629,
    name: "reset-password-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
