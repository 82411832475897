import { MenuAccessibleItem } from '~/types/user/uiSettings'
import putUser from '~/connectors/user/put-user'
import getUser from '~/connectors/user/get-user'

const initialState = {}

export default {
  namespaced: true,
  state: () => ({
    user: initialState
  }),
  getters: {
    user (state: any) {
      return state.user
    },
    isLoaded (state: any) {
      return !!state.user.company_name
    },
    canDepositMoney (state: any) {
      const balanceRequiredFields = ['company_name', 'inn', 'gen_dir', 'on_the_based']
      return balanceRequiredFields.every(field => !!state.user[field])
    },
    userAllowedRoutes (state: any) {
      return state.user?.ui_settings?.menu || {}
    },
    isUserAllowedForRoute: (_state: any, getters: any) => (param: MenuAccessibleItem) => {
      if (param in getters.userAllowedRoutes) {
        return getters.userAllowedRoutes[param]
      }
      return false
    }
  },
  actions: {
    async fetchUser (ctx: any) {
      const res = await getUser()
      ctx.commit('setUser', res.data)
    },
    setInfo (ctx: any, payload: any) {
      ctx.commit('setUser', payload)
    },
    setLastReestrUpdated (ctx: any, payload: any) {
      ctx.commit('setLastReestrUpdated', payload)
    },
    async updateUser (ctx: any, payload: any) {
      const res = await putUser(payload)
      ctx.commit('setUser', res.data)
    }
  },
  mutations: {
    setUser (state: any, payload: any) {
      state.user = payload
    },
    resetInfo (state: any) {
      state.user = initialState
    },
    setLastReestrUpdated (state: any, payload: any) {
      state.user.last_reestr_updated_at = payload
    }
  }
}
