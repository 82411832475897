
import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'

@Component({})
export default class UIDialog extends Vue {
  @Prop({ default: true }) active: boolean
  @Prop() title?: string
  @Prop({ default: 'md' }) size: 'xs' | 'sm' | 'md' | 'md2' | 'lg' | 'xl' | 'auto'

  @Emit()
  close () {

  }

  get sizeVariants () {
    const variants = {
      xs: '300px',
      sm: '399px',
      md: '599px',
      md2: '633px',
      lg: '700px',
      xl: '90%',
      auto: 'auto'
    }
    return variants[this.size] ? variants[this.size] : '50%'
  }
}
